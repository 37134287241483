@media screen and (min-width: 600px) {
    .noMatchText{
        margin-top: 24px!important;
        font-size: 48px!important;
    }
    }

@media screen and (min-width: 960px) {
    .noMatchText{
    font-size: 48px!important;
    line-height: 1!important;
    }
    .textoChicoNoMatch{
        font-size: 18px!important;
    }
    }



.linkeoNoMatch{
    display: block!important;
    font-weight: 400!important;
    margin-top: 24px!important;
    color: #004e56 !important;
}

.proxMod{
display: flex!important;
flex:1 1!important;
flex-direction: column!important;
align-items: center!important;
justify-content: center!important;
padding: 16px!important;
}

.proxMod2{
    width: 100%!important;
    text-align: center!important;
    max-width: 768px!important;

    }

    .proxMod3{
        min-height: calc((100vw - 32px) * 2 / 3);
    }

    @media screen and (min-width: 817px) {
.proxMod3{
    min-height: 512px;
}
    }